<template>
  <div>
    <v-row no-glutters class="body-rows">
      <v-col v-if="iframurl.stat" cols="12" md="7" class="left-cols1 pa-0">
        <iframe :src="iframurl.url" title="CAMS" width="100%" height="93%" style="border: none"></iframe>
        <v-btn @click="iframurl = []" class="primary--text font-weight-bold text-none" block small text>Return to segments</v-btn>
      </v-col>
      <v-col v-else cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/bank" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
          Step 4 of 6
        </p>
        <!-- <p v-if="segis == 0" class="headline font-weight-black mb-2">Select the segment that you want to trade.
                </p>
                <p v-else-if="segis == 1 && IncomeProofselect && incomeimagedata"
                    class="headline font-weight-black mb-2">Selected segment that you can trade.
                </p> -->
        <p v-if="segis == 1 && !IncomeProofselect && !incomeimagedata" class="headline font-weight-black mb-2">{{ IncomeProofselect == "cams" ? "Your bankstatement from CAMS" : "Upload" }} {{ IncomeProofselect >= 0 ? segprooflist[IncomeProofselect].txt : null }}</p>
        <p v-else class="headline font-weight-black mb-2"> What’s your trading Preference? </p>

        <p class="subtitle-1 font-weight-regular txt-666" :class="IncomeProofselect && !incomeimagedata ? '' : 'mb-0'">
          {{
            IncomeProofselect && !incomeimagedata
              ? `As per the latest guidelines by SEBI & Exchange, you need to upload your income proof to trade in
                    Derivatives.`
              : " Let’s Get Your Portfolio Started!"
          }}
        </p>
        <v-alert v-if="rejectdata.app_status == 'rejected' ? rejectdata.stage_wise_approval.segment == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
          <span class="subtitle-2 font-weight-regular"
            >Your Segment have been rejected due <b>{{ rejectdata.finan_reject_reason }}</b></span
          >
        </v-alert>
        <v-alert v-if="rejectdata.data_type && rejectdata.data_type.cam_code == 402" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
          <span class="caption">We weren't able to download your bank statement from CAMSfinserv, so kindly upload any of the below-listed files.</span>
        </v-alert>

        <div v-if="segis == 0 && !dataloader">
          <div v-if="nocam">
            <v-chip-group column class="mb-4">
              <v-chip :disabled="fno == 'Yes' || segis != 0" @click="(tag.sec = !tag.sec), exchisSec(tag.val)" :color="tag.sec ? '#000' : '#666666'" :text-color="tag.sec ? '#fff' : '#666666'" v-for="(tag, k) in exchitems" :outlined="!tag.sec" :key="k">
                {{ tag.txt }}
              </v-chip>
            </v-chip-group>

            <div v-if="exchitems[0].sec || exchitems[1].sec" class="mb-2">
              <p class="subtitle-1 txt-666 lh-16 mb-1 mt-4">Want to amplify your trades with MTF? Say yes for more buying power and bigger opportunities.</p>
              <v-chip-group column>
                <v-chip :disabled="fno == 'Yes' || segis != 0" @click="mtf = tag" :color="mtf == tag ? '#000' : '#666666'" :text-color="mtf == tag ? '#fff' : '#666666'" v-for="tag in mtftag" :outlined="mtf != tag" :key="tag">
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </div>

            <p class="caption font-weight-regular txt-666 mb-2">Heads up: SEBI Requires Income Proof for Trading Derivatives. Just upload it, and you’re good to go! {{ fno != "Yes" ? "your income proof" : "any one of the below-mentioned documents" }} to trade in Derivatives.</p>
            <v-btn v-if="fno != 'Yes'" height="48px" :disabled="!exchitems[0].sec && !exchitems[1].sec && !exchitems[2].sec && !exchitems[3].sec && !exchitems[4].sec && !exchitems[5].sec" @click="exchisSec('setNext')" color="#000" class="white--text text-none rounded-pill elevation-0 mt-4"
              ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
            >

            <v-card v-if="fno != 'Yes'" style="border: 1px solid #ebeef0 !important" color="rgba(246, 247, 247, 0.5)" class="px-6 py-4 text-left mx-auto elevation-0 rounded-xl mt-6" width="100%">
              <p class="sec-fn fs-16 font-weight-medium">Risk disclosures on derivatives by SEBI:</p>
              <p class="txt-666 fs-12 mb-2">- 9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses.</p>
              <p class="txt-666 fs-12 mb-2">- Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading losses as transaction costs.</p>
              <p class="txt-666 fs-12 mb-2">- On an average, loss makers registered net trading loss close to 50,000</p>
              <p class="txt-666 fs-12 mb-0">- Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost.</p>
            </v-card>
          </div>

          <div v-if="fno == 'Yes'" class="mt-8">
            <div class="mt-4" v-if="IncomeProofselect === 0">
              <v-card v-if="nocam" @click="linktype = 'cam'" style="border: 1px solid #ddd !important" color="transparent" class="pa-4 elevation-0 rounded-lg mb-6">
                <v-row no-glutters>
                  <v-col cols="10" sm="11">
                    <p class="font-weight-medium fs-18 mb-2">Auto-fetch your bank statement<v-chip color="#66BB6A" text-color="#fff" class="rounded-md px-1 ml-2" x-small label>Recommended</v-chip></p>
                    <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px">ZEBU Partnered with CAMSFinserv (RBI Licensed Account aggregator)</p>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <v-radio-group v-model="linktype" column>
                      <v-radio @click="linktype = 'cam'" label="" color="#000000" value="cam"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>

              <v-card v-if="linktype != 'cam'" @click="(linktype = 'man'), (segis = 1), (IncomeProofselect = 0)" style="border: 1px solid #ddd !important" color="transparent" class="pa-4 elevation-0 rounded-lg">
                <v-row no-glutters>
                  <v-col cols="11">
                    <p class="font-weight-medium fs-18 mb-2">Upload bank statement</p>
                    <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px">Of last 6 months ({{ sixm }} to {{ currm }})</p>
                  </v-col>
                  <v-col cols="1">
                    <v-radio-group v-model="linktype" column>
                      <v-radio @click="(linktype = 'man'), (segis = 1), (IncomeProofselect = 0)" label="" color="#000000" value="man"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div v-if="linktype == 'cam'">
              <p class="font-weight-medium fs-17 mb-0 mt-4 mt-md-6">
                Enter your registered mobile number in the bank
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                  <path
                    d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                    fill="#EF5350"
                  />
                </svg>
              </p>
              <v-text-field placeholder="Enter mobile number to begin" required type="number" hide-spin-buttons v-model="mobile_num" @keypress="phonevalidation($event)" counter="10" :error-messages="numberErrors" @keyup.enter="bankstatmentRequest()" class="txt-field" @blur="$v.mobile_num.$touch()">
                <template #prepend-inner>
                  <div class="mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M11.6667 0.333008H4.33333C3.89147 0.333537 3.46785 0.509302 3.15541 0.821748C2.84296 1.13419 2.6672 1.55781 2.66667 1.99967V13.9997C2.6672 14.4415 2.84296 14.8652 3.15541 15.1776C3.46785 15.49 3.89147 15.6658 4.33333 15.6663H11.6667C12.1085 15.6658 12.5321 15.49 12.8446 15.1776C13.157 14.8652 13.3328 14.4415 13.3333 13.9997V1.99967C13.3328 1.55781 13.157 1.13419 12.8446 0.821748C12.5321 0.509302 12.1085 0.333537 11.6667 0.333008ZM12.6667 12.9997C12.6667 13.0881 12.6315 13.1729 12.569 13.2354C12.5065 13.2979 12.4217 13.333 12.3333 13.333H3.66667C3.57826 13.333 3.49348 13.2979 3.43097 13.2354C3.36845 13.1729 3.33333 13.0881 3.33333 12.9997V1.99967C3.33333 1.91127 3.36845 1.82648 3.43097 1.76397C3.49348 1.70146 3.57826 1.66634 3.66667 1.66634H12.3333C12.4217 1.66634 12.5065 1.70146 12.569 1.76397C12.6315 1.82648 12.6667 1.91127 12.6667 1.99967V12.9997Z"
                        fill="#121212"
                      />
                      <path
                        d="M9.66666 4H6.33333C6.24492 4 6.16014 3.94732 6.09763 3.85355C6.03511 3.75979 6 3.63261 6 3.5C6 3.36739 6.03511 3.24021 6.09763 3.14645C6.16014 3.05268 6.24492 3 6.33333 3H9.66666C9.75507 3 9.83985 3.05268 9.90237 3.14645C9.96488 3.24021 10 3.36739 10 3.5C10 3.63261 9.96488 3.75979 9.90237 3.85355C9.83985 3.94732 9.75507 4 9.66666 4Z"
                        fill="#121212"
                      />
                    </svg>
                  </div>
                </template>
              </v-text-field>

              <div class="d-inline-flex">
                <v-btn height="48px" @click="(IncomeProofselect = 0), (befno = 'Yes'), (fno = 'Yes'), (linktype = 'man'), (segis = 1)" outlined style="border: 2px solid #444 !important" color="#000" class="text-none rounded-pill elevation-0 mt-10 mr-4"
                  ><span class="subtitle-1 font-weight-medium px-6">Upload manually</span></v-btn
                >

                <v-btn v-if="camretry <= 30" height="48px" :disabled="$v.mobile_num.$invalid" @click="bankstatmentRequest()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-10"> {{ camretry == 0 ? "Continue" : "Retry" }} </v-btn>
              </div>
            </div>

            <div v-if="IncomeProofselect === '' && linktype != 'cam'">
              <v-card
                v-for="(q, w) in segprooflist.slice(0, showmore)"
                :key="w"
                @click="q.val == 'bs' ? (rejectdata.data_type && rejectdata.data_type.cam_code == 402 ? ((linktype = 'man'), (segis = 1)) : '', (IncomeProofselect = q.idx)) : ((IncomeProofselect = q.idx), docUpload())"
                style="border: 1px solid #ddd !important"
                color="transparent"
                class="pa-4 elevation-0 rounded-lg mb-5"
              >
                <v-row no-glutters>
                  <v-col cols="10" sm="10">
                    <p class="font-weight-medium fs-18 mb-2">{{ q.txt }} <v-chip v-if="q.rec" color="#66BB6A" text-color="#fff" class="rounded-md px-1 ml-2" x-small label>Recommended</v-chip></p>
                    <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px">{{ q.sub }}</p>
                  </v-col>
                  <v-col cols="1" sm="2">
                    <v-radio-group v-model="IncomeProofselect" column>
                      <v-radio @click="q.val == 'bs' ? (rejectdata.data_type && rejectdata.data_type.cam_code == 402 ? ((linktype = 'man'), (segis = 1)) : '', (IncomeProofselect = q.idx)) : ((IncomeProofselect = q.idx), docUpload())" label="" color="#000000" :value="q.idx"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>

              <v-card v-if="showmore == 4" @click="showmore = 13" style="border: 1px solid #ddd !important" color="transparent" class="pa-4 elevation-0 rounded-lg mb-5 text-center"> Show more </v-card>
            </div>
          </div>
        </div>
        <div v-else-if="segis == 1 && !dataloader" class="pt-4">
          <div v-if="!incomeFile" class="mb-16 mb-md-0">
            <div class="form-group">
              <v-card color="rgba(246, 247, 247, 0.5)" @ondragover="onChange" :class="dragging ? 'dropAreaDragging' : ''" @dragenter="dragging = true" @dragend="dragging = false" @dragleave="dragging = false" class="fileupload elevation-0 rounded-lg text-center" style="border: 1px dashed #d0d3d6">
                <div class="innerfilup">
                  <img class="mb-2" src="@/assets/file drop.svg" width="48px" />
                  <p class="font-weight-bold fs-18" style="color: #0037b7 !important">Click here to upload</p>
                  <p class="mb-0 txt-666 subtitle-1 font-weight-regular" style="line-height: 18px">You can drop your {{ IncomeProofselect >= 0 ? segprooflist[IncomeProofselect].sub : "proof" }} here to be uploaded</p>
                </div>
                <input type="file" id="items" accept="application/pdf" name="items[]" @change="onChange" />
              </v-card>
            </div>
          </div>

          <div v-else class="mb-16 mb-md-0">
            <v-chip-group column class="mb-4">
              <v-chip readonly color="#000" text-color="#fff" v-for="(tag, k) in exchitems" :key="k" :class="exchitems[0].sec ? (tag.val != 'all' ? '' : 'd-none') : tag.sec ? '' : 'd-none'">
                {{ tag.txt }}
              </v-chip>
            </v-chip-group>
            <p class="mb-2">
              <span class="txt-666">Proof type</span> : <b>{{ segprooflist[IncomeProofselect].txt }}</b>
            </p>
            <p>
              <span class="txt-666">File names</span> : <b>{{ incomeimagedata.name }}</b>
            </p>
            <iframe style="border: thin dashed #cfd8dc !important; border-radius: 8px" :src="incomeimage" width="100%" height="240"></iframe>

            <div v-if="incomePassRequired" class="pb-4">
              <p class="font-weight-medium fs-17 mb-0 mt-4">
                Your {{ IncomeProofselect >= 0 ? segprooflist[IncomeProofselect].txt : null }} file is protected.
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                  <path
                    d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                    fill="#EF5350"
                  />
                </svg>
              </p>
              <v-text-field
                :color="errorincomepass ? 'red' : ''"
                :error="errorincomepass"
                :readonly="incomepasserror == 'T'"
                :type="showincomepass ? 'text' : 'password'"
                v-model="incomepass"
                prepend-icon=""
                hide-details
                @keyup.enter="verifyIncomePassword()"
                placeholder="Enter your file password for Encryption"
              >
                <template #append>
                  <div class="d-inline-flex">
                    <v-icon @click="showincomepass = !showincomepass">{{ showincomepass ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
                    <v-btn text class="text-none" :loading="passloader" @click="incomepasserror != 'T' ? verifyIncomePassword() : ''">
                      <v-icon size="18" v-if="incomepasserror != 'T'">mdi-send</v-icon>
                      <v-icon v-else size="18" color="#37A668">mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
              <p v-if="errorincomepass" style="color: red; font-size: 14px">{{ incomepasserror }}</p>
            </div>
          </div>
          <v-row no-glutters class="mt-4 d-none d-md-flex">
            <v-col cols="6" class="text-right">
              <v-btn height="48px" @click="setCleareall()" color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">Clear all</span></v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                v-if="incomePassRequired == true ? (incomepasserror == 'T' ? true : false) : true"
                height="48px"
                :disabled="!incomeFile || incomePassRequired == null"
                @click="incomereject ? toNext() : fileUpload()"
                :loading="cardloader"
                color="#000"
                class="white--text text-none rounded-pill elevation-0"
                ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
              >
              <v-btn v-else disabled height="48px" :loading="cardloader" color="#000" class="white--text text-none rounded-pill elevation-0"><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
            </v-col>
          </v-row>

          <v-footer padless color="#fff" class="btn-footer btn-footer px-3 pt-3 pb-1 d-md-none">
            <v-row>
              <v-col cols="6" class="text-right">
                <v-btn height="48px" @click="setCleareall()" block color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">Clear all</span></v-btn>
              </v-col>
              <v-col cols="6" :class="IncomeProofselect == 'cams' ? 'text-center' : 'text-left'">
                <v-btn
                  v-if="incomePassRequired == true ? (incomepasserror == 'T' ? true : false) : true"
                  height="48px"
                  :disabled="!incomeFile || incomePassRequired == null"
                  block
                  @click="incomereject ? toNext() : fileUpload()"
                  :loading="cardloader"
                  color="#000"
                  class="white--text text-none rounded-pill elevation-0"
                  ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
                >

                <v-btn v-else height="48px" disabled block :loading="cardloader" color="#000" class="white--text text-none rounded-pill elevation-0"><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
              </v-col>
            </v-row>
          </v-footer>
        </div>
        <div v-else-if="segis == 2 && !dataloader" class="pt-4">
          <v-card width="100%" class="text-center pt-8 elevation-0 mb-16 mb-md-0" color="transparent">
            <img src="@/assets/bankverifi.svg" alt="bankverifi" />
            <p class="fs-18 font-weight-medium">Consent have been submitted successfully</p>
            <v-alert border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2 bank-d" color="warning" elevation="0">
              <span class="subtitle-2">It will take some time to download your bank statement from CAMSfinserv its in progress.</span>
            </v-alert>

            <v-btn height="48px" @click="toNext()" :loading="cardloader" color="#000" class="white--text text-none rounded-pill elevation-0 mt-10"><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
          </v-card>
        </div>
        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img v-if="fno == 'Yes'" src="@/assets/seg2.svg" class="rgt-img" alt="rgt-img" />
          <img v-else src="@/assets/seg1.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
    <v-overlay :value="overlay" class="rounded-0">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
        <p class="font-weight-bold fs-18 white--text mt-2">fetching your bank statement from CAMS...</p>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";
import {validationMixin} from "vuelidate";
import {required, maxLength, numeric, minValue} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    mobile_num: {required, maxLength: maxLength(10), numeric, minValue: minValue(6000000000)},
  },
  data: () => ({
    name: "",
    fnos: ["Yes", "No"],
    fno: "",
    befno: "",

    segis: 0,
    sixm: "",
    trem: "",
    currm: "",
    curry: "",
    /////////////////
    dragging: false,

    incomeFile: "",
    incomereject: false,
    incomeimage: "",
    incomeimagedata: [],
    IncomeProofselect: "",
    incomepass: "",
    cardloader: false,
    dataloader: null,
    linktype: null,
    client_no: null,
    srcref: null,
    tranid: null,
    incomePassRequired: false,

    statusValue: "",
    bankRegMobile: null,

    retrybank: false,
    bankUname: null,
    accstatus: null,
    tran_no: null,
    overlay: false,

    showincomepass: false,
    errorincomepass: true,
    incomepasserror: "",
    passloader: false,
    heisStudent: null,
    couponInput: null,
    successcoupon: null,

    mobile_num: "",
    ash: false,
    seg_cam: null,
    camretry: 0,
    nocam: null,
    rejectdata: [],

    nb_equity: false,
    nb_fno: false,
    nb_debt: false,
    nb_currency: false,
    nb_commodity: false,
    m_commodity: false,

    exchitems: [
      {txt: "All", val: "all", sec: false},
      {txt: "Equity", val: "EQY", sec: false},
      {txt: "F&O", val: "fno", sec: false},
      {txt: "Debt", val: "debt", sec: false},
      {txt: "Currency", val: "cur", sec: false},
      {txt: "Commodity", val: "Der", sec: false},
    ],
    annualstatus: null,

    segprooflist: [],
    showmore: 4,
    iframurl: {
      url: "",
      stat: "",
    },

    mtf: "Yes",
    mtftag: ["Yes", "No"],
  }),

  created() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && response.data.stage_wise_approval.segment != "yes" && (p.stage == "segment" || p.stage == "declartaion" || p.stage == "signature" || p.stage == "nominee" || p.stage == "selfie" || p.stage == "submit")) {
          axiosThis.$emit("clicked", 4, response.data);
          response.data.app_status == "rejected" && response.data.stage_wise_approval.segment == "no" ? (axiosThis.rejectdata = response.data) : (axiosThis.rejectdata = []);
          axiosThis.mobile_num = p.cam_mobile ? p.cam_mobile : atob(localStorage.getItem("hash"));
          axiosThis.seg_cam = p.seg_cam == true ? true : false;
          axiosThis.camretry = p.cam_retry > 0 ? p.cam_retry : 0;
          axiosThis.annualstatus = response.data.annual_income == "below 1 lac" ? true : false;
          axiosThis.mtf = response.data.data_type.MTf;
          if (p.cam_code != 402) {
            if (p.seg_cam == true) {
              axiosThis.befno = "Yes";
              axiosThis.fno = "Yes";
              axiosThis.linktype = "cam";
              axiosThis.segis = p.cam_segis == true ? 2 : 0;
              axiosThis.srcref = p.srcref;
            }
            axiosThis.nocam = true;
          } else {
            axiosThis.rejectdata = response.data;
            axiosThis.linktype = "man";
            axiosThis.befno = "Yes";
            axiosThis.fno = "Yes";
            axiosThis.nocam = false;
          }
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
    this.sixm = this.subtractMonth(new Date(), 6);
    this.trem = this.subtractMonth(new Date(), 3);
    this.currm = this.subtractMonth(new Date(), 1);
    this.curry = `${new Date().getFullYear()}`.slice(2);
    this.segprooflist = [
      {idx: 0, txt: "Bank statement", val: "bs", sub: `Of last 6 months (${this.sixm} to ${this.currm})`, rec: true},
      {idx: 1, txt: "Salary slips", val: "ss", sub: `Of last 3 months (${this.trem} to ${this.currm})`},
      {idx: 2, txt: "ITR", val: "itr", sub: `Of the most recent tax year (FY${this.curry - 1}-${this.curry})`},
      {idx: 3, txt: "Form 16B", val: "form", sub: `Of the most recent tax year (FY${this.curry - 1}-${this.curry})`},
      {idx: 4, txt: "Holding Statement", val: "hs", sub: `Copy of 3 months demat account holding statement`},
      {idx: 5, txt: "Net worth certificate", val: "nwc", sub: `latest one or at the end of last financial year of 20${this.curry - 2}-${this.curry - 1}`},
      {idx: 6, txt: "Life Insurance Policy", val: "lip", sub: `any insurance co. with minimum cover of two lakh`},
      {idx: 7, txt: "KVP, NSC, IVP & Bonds", val: "bonds", sub: `Should be valid as on date of submission and have a min value of Rs. 10,000/-`},
      {idx: 8, txt: "Bank FD's, Corporate FD's", val: "fds", sub: `Should be valid as on date of submission and have a min value of Rs. 10,000/-`},
      {idx: 9, txt: "Mutual Fund Statement", val: "mfs", sub: `Statement should be more than 2 months old. The latest NAV and value should be more than Rs. 10,000/-`},
      {idx: 10, txt: "Ownership of Assets such as Gold", val: "gold", sub: `Value as mentioned on the bill should be Rs. 10,000/-`},
      {idx: 11, txt: "Letter from Society", val: "lfs", sub: `The letter should state ownership of the flat. It should be on the letter head of the society`},
      {idx: 12, txt: "RC Book of the Vehicle", val: "rcb", sub: "List of acceptable documents."},
    ];
  },
  async mounted() {
    this.dataloader = true;
    var url = new URL(window.location.href);
    var child = url.searchParams.get("kid");
    var status = url.searchParams.get("status");
    var errorCode = url.searchParams.get("errorCode");
    var srcref = url.searchParams.get("srcref");
    var userid = url.searchParams.get("userid");
    var txnid = url.searchParams.get("txnid");

    if (child == "iframe" && status && errorCode) {
      await this.getSegmentdata();
      this.overlay = true;
      sessionStorage.setItem("concernstatus", status && errorCode == 0 ? {status: status, errorCode: errorCode, txnid: txnid} : errorCode == 0 ? status : null);
      this.client_no = userid.split("@")[0];
      this.srcref = srcref;
      this.tranid = txnid;
      this.bankstatmentCheckRequest();
    } else if (status && errorCode) {
      window.top.location.href = `${"https://oa.mynt.in/segment"}${url.search}&kid=iframe`;
      // https://oa.mynt.in/segment?status=failed&errorCode=1&sessionid=7bbc9699-8902-417b-9680-9ce4f3d8c1c9&userid=9444856459%40CAMSAA&txnid=0e0e988e-d481-47f2-a871-f4e7c7a08869&srcref=6fa01999-0564-48ec-96a3-456f00047440&kid=iframe
    } else {
      await this.getSegmentdata();

      var config = {
        method: "post",
        url: api.api_url + "/check_file?mob=" + atob(localStorage.getItem("hash")),
        headers: {},
      };
      var axiosThis = this;
      axios(config)
        .then(function (response) {
          if ((response.data != "no file data found" && axiosThis.linktype != "cam" && axiosThis.nocam == true) || response.data.incometype == "cams") {
            axiosThis.fno = response.data.fno;
            axiosThis.befno = response.data.fno;
            if (response.data.fno == "Yes") {
              axiosThis.incomereject = response.data["Income File"] == true ? true : false;
              let index = axiosThis.segprooflist.findIndex((x) => x.txt == response.data.incometype);
              axiosThis.IncomeProofselect = response.data.incometype == "cams" ? axiosThis.segprooflist[0].idx : index >= 0 ? axiosThis.segprooflist[index].idx : response.data.incometype;
              if (axiosThis.rejectdata.app_status == "rejected" && axiosThis.rejectdata.stage_wise_approval.segment == "no") {
                axiosThis.incomereject = false;
                axiosThis.IncomeProofselect = "";
                axiosThis.linktype = "man";
                axiosThis.befno = "Yes";
                axiosThis.fno = "Yes";
                axiosThis.nocam = false;
                axiosThis.dataloader = false;
              } else if (response.data["Income File"] == true) {
                let name = "BP";
                let link = response.data["Income"];
                axiosThis.getFileis(name, link);
                axiosThis.segis = 1;
              } else {
                axiosThis.dataloader = false;
              }
            } else {
              axiosThis.dataloader = false;
            }
          } else {
            axiosThis.dataloader = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    }
  },

  methods: {
    async getSegmentdata() {
      var config = {
        method: "post",
        url: api.api_url + "/segmentUpdation?mob=" + atob(localStorage.getItem("hash")),
        headers: {},
      };
      var axiosThis = this;
      await axios(config)
        .then(function (response) {
          var data = response.data;
          var NSE = data["NSEseg"];
          var MCX = data["MCXseg"];
          if (NSE) {
            if (NSE.split(",").includes("Cash")) {
              axiosThis.nb_equity = true;
              if (axiosThis.mtf != "Yes" && axiosThis.mtf != "No") {
                axiosThis.mtf = "Yes";
              }
            }
            if (NSE.split(",").includes("Currency")) {
              axiosThis.nb_currency = true;
            }
            if (NSE.split(",").includes("F&O")) {
              axiosThis.nb_fno = true;
            }
            if (NSE.split(",").includes("Debt")) {
              axiosThis.nb_debt = true;
            }
            if (NSE.split(",").includes("Commodity")) {
              axiosThis.nb_commodity = true;
            }
          }
          if (MCX == "True") {
            axiosThis.m_commodity = true;
          }

          if (axiosThis.nb_equity == true && axiosThis.nb_fno == true && axiosThis.nb_debt == true && axiosThis.nb_currency == true && axiosThis.nb_commodity == true && axiosThis.m_commodity == true) {
            axiosThis.exchitems[0].sec = true;
            axiosThis.exchitems[1].sec = false;
            axiosThis.exchitems[2].sec = false;
            axiosThis.exchitems[3].sec = false;
            axiosThis.exchitems[4].sec = false;
            axiosThis.exchitems[5].sec = false;
            axiosThis.fno = "Yes";
            axiosThis.befno = "Yes";
            axiosThis.IncomeProofselect = "";
          } else {
            axiosThis.exchitems[0].sec = false;
            axiosThis.exchitems[1].sec = axiosThis.nb_equity;
            axiosThis.exchitems[2].sec = axiosThis.nb_fno;
            axiosThis.exchitems[3].sec = axiosThis.nb_debt;
            axiosThis.exchitems[4].sec = axiosThis.nb_currency;
            axiosThis.exchitems[5].sec = axiosThis.m_commodity;
            if (axiosThis.nb_fno || axiosThis.nb_currency || axiosThis.m_commodity) {
              axiosThis.fno = "Yes";
              axiosThis.befno = "Yes";
              axiosThis.IncomeProofselect = "";
            } else {
              axiosThis.fno = "No";
              axiosThis.befno = "No";
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    toNext() {
      this.$emit("changed", 0);
      if (this.nocam == false) {
        if (this.rejectdata.app_status == "rejected" && this.rejectdata.stage_wise_approval.segment == "no") {
          this.$emit("steged", "segment");
        } else {
          this.$emit("clicked", "submit");
        }
      } else {
        if (this.rejectdata.app_status == "rejected" && this.rejectdata.stage_wise_approval.segment == "no") {
          this.$emit("steged", "segment");
        } else {
          this.$emit("clicked", "declartaion");
        }
      }
    },
    exchisSec(val) {
      if (val != "setNext") {
        if (val == "all" || (this.exchitems[1].sec && this.exchitems[2].sec && this.exchitems[3].sec && this.exchitems[4].sec && this.exchitems[5].sec)) {
          this.exchitems[0].sec = true;
          this.exchitems[1].sec = false;
          this.exchitems[2].sec = false;
          this.exchitems[3].sec = false;
          this.exchitems[4].sec = false;
          this.exchitems[5].sec = false;
        } else {
          this.exchitems[0].sec = false;
        }
      } else {
        if (this.exchitems[0].sec == true) {
          this.nb_equity = true;
          this.nb_fno = true;
          this.nb_debt = true;
          this.nb_currency = true;
          this.nb_commodity = true;
          this.m_commodity = true;
          if (this.annualstatus && this.exchitems[0].sec) {
            this.$emit("snack", 2, "Your income bracket is below 1 lakh, Trading is limited to equity under SEBI regulations.");
          } else {
            this.fno = "Yes";
            this.segment_selection("Yes");
          }
        } else {
          this.nb_equity = this.exchitems[1].sec;
          this.nb_fno = this.exchitems[2].sec;
          this.nb_debt = this.exchitems[3].sec;
          this.nb_currency = this.exchitems[4].sec;
          this.nb_commodity = this.exchitems[5].sec;
          this.m_commodity = this.exchitems[5].sec;
          if (this.exchitems[2].sec || this.exchitems[4].sec || this.exchitems[5].sec) {
            if (this.annualstatus) {
              this.$emit("snack", 2, "Your income bracket is below 1 lakh, Trading is limited to equity under SEBI regulations.");
            } else {
              this.fno = "Yes";
              this.segment_selection("Yes");
            }
          } else {
            this.fno = "No";
            this.segment_selection("No");
          }
        }
      }
    },
    setCleareall() {
      if (this.nocam) {
        this.fno = "";
        this.befno = "";
        this.IncomeProofselect = "";
        this.segis = 0;
        this.linktype = "";
        this.incomepasserror = "";
        this.exchitems[0].sec = false;
        this.exchitems[1].sec = false;
        this.exchitems[2].sec = false;
        this.exchitems[3].sec = false;
        this.exchitems[4].sec = false;
        this.exchitems[5].sec = false;
      } else {
        this.fno = "Yes";
        this.befno = "Yes";
        this.IncomeProofselect = "";
        this.segis = 0;
        this.linktype = "man";
        this.incomepasserror = "";
      }
      this.showmore = 4;
      this.$emit("changed", 4);
      this.bankDatac();
    },
    phonevalidation($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
        // && keyCode !== 46  this if for dot key
        $event.preventDefault();
      }
      if (this.mobile_num.length >= 10) {
        $event.preventDefault();
      }
    },
    subtractMonth(date, n) {
      date.setMonth(date.getMonth() - n);
      var strArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var m = strArray[date.getMonth()];
      var y = `${date.getFullYear()}`.slice(2);
      return m + " " + y;
    },
    bankDatac() {
      this.incomeFile = "";
      this.incomeimagedata = [];
      this.incomeimage = "";
      this.incomereject = false;
      this.IncomeProofselect = "";
      this.segis = 0;
      this.dragging = false;
    },
    docUpload() {
      this.segis = 1;
    },

    onChange(e) {
      let file = e.target.files[0] || e.dataTransfer.files[0];
      if (file["type"] == "application/pdf" && file.size <= 4000000) {
        this.incomeFile = file;
        this.financialIncomeCheck();
        this.incomeimage = window.URL.createObjectURL(this.incomeFile);
        this.incomeimagedata["name"] = this.incomeFile.name;
        this.incomeimagedata["size"] = (this.incomeFile.size / 1048576).toFixed(1);

        // const reader = new FileReader();
        // const fileInfo = e.target.files[0];
        // if (fileInfo) {
        //     reader.readAsBinaryString(e.target.files[0]);
        //     reader.onloadend = () => {
        //         const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
        //         console.log('Number of Pages:', count);
        //     }
        // }
      } else {
        this.cardloader = false;
        this.$emit("snack", 2, file.size > 4000000 ? "PDF size should be less than 4 MB!" : "Only PDF file formats are supported.");
      }
    },
    async getFileis(name, link) {
      let config = {
        method: "post",
        url: api.api_url + `/filsend?path=${link}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      var axiosThis = this;
      await axios
        .request(config)
        .then((response) => {
          if (response.data) {
            let hash = atob(localStorage.getItem("hash"));

            if (name == "BP") {
              let url = `${api.api_img}/${link}`;
              let blob = new Blob([url]);
              let file = new File([blob], `${url.split(`${hash}/`)[1].split(".")[0]}`, {type: `image/${url.split(`${hash}/`)[1].split(".")[1]}`});
              axiosThis.incomeimage = url;
              axiosThis.incomeFile = file;
              axiosThis.incomeimagedata["name"] = this.incomeFile.name;
              axiosThis.incomeimagedata["size"] = (this.incomeFile.size / 1048576).toFixed(1);
              axiosThis.incomeimagedata["from"] = "be";
            }
            axiosThis.dataloader = false;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },

    financialIncomeCheck() {
      if (this.incomeFile && this.incomeFile["type"] == "application/pdf") {
        let formData = new FormData();
        formData.append("financial", this.incomeFile);
        var axiosThis = this;
        axios
          .post(api.api_url + "/financialCheck/" + atob(localStorage.getItem("hash")), formData, {
            headers: {"Content-Type": "multipart/form-data"},
          })
          .then(function (response) {
            if (response.data["data"] == "pdferror") {
              axiosThis.incomeFile = "";
              axiosThis.incomeimagedata = [];
              axiosThis.incomeimage = "";
              axiosThis.$emit("snack", 2, response.data.emsg ? response.data.emsg : response.data);
              axiosThis.incomePassRequired = null;
            } else if (response.data["data"] == true) {
              axiosThis.incomePassRequired = true;
            } else {
              axiosThis.incomePassRequired = false;
            }
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.$emit("snack", 0, error);
          });
      } else {
        this.incomePassRequired = false;
      }
    },
    verifyIncomePassword() {
      if (this.incomepass) {
        this.passloader = true;
        let formData = new FormData();
        formData.append("financial", this.incomeFile);
        var axiosThis = this;
        axios
          .post(api.api_url + "/verifyIncomePassword/" + this.incomepass, formData, {
            headers: {"Content-Type": "multipart/form-data"},
          })
          .then(function (response) {
            axiosThis.passloader = false;
            if (response.data["data"] == false) {
              axiosThis.incomepasserror = "Invalid password";
              axiosThis.errorincomepass = true;
              return false;
            } else if (response.data["data"] == true) {
              axiosThis.errorincomepass = false;
              axiosThis.incomepasserror = "T";
              return true;
            } else {
              axiosThis.incomepasserror = "Password required";
              return true;
            }
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.passloader = false;
            axiosThis.$emit("snack", 0, error);
          });
      } else {
        axiosThis.incomepasserror = "Password required";
      }
    },
    setMTFstat(list) {
      let configo = {
        method: "post",
        url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({MTf: this.mtf, seglist: list}),
      };
      axios
        .request(configo)
        .then(() => {})
        .catch(() => {});
    },
    setNoCams() {
      let datao = JSON.stringify({
        seg_cam: "",
        cam_mobile: "",
        cam_rmobile: "",
        cam_retry: "",
        cam_code: "",
        srcref: "",
      });
      let configo = {
        method: "post",
        url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=rem`,
        headers: {
          "Content-Type": "application/json",
        },
        data: datao,
      };
      let axiosThis = this;
      axios
        .request(configo)
        .then((response) => {
          if (response.data.data_type.cam_mobile) {
            //
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    fileUpload() {
      if (this.incomereject == false && this.incomeFile && this.incomeFile["type"] == "application/pdf") {
        this.cardloader = true;
        this.setNoCams();
        let formData = new FormData();

        let type = this.IncomeProofselect >= 0 ? this.segprooflist[this.IncomeProofselect].txt : null;
        if (this.incomereject == false && this.incomeFile) {
          formData.append("financial", this.incomeFile);
          formData.append("financialPassword", this.incomepass);
          formData.append("incometype", type);
          formData.append("fno", "Yes");
        }

        var axiosThis = this;
        axios
          .post(api.api_url + `/uploadSignupUserFiles/` + atob(localStorage.getItem("hash")), formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            if (response.data["msg"] == "All Files Upload sucessfully") {
              axiosThis.toNext();
            } else {
              axiosThis.cardloader = false;
              axiosThis.$emit("snack", 2, response.data.msg ? response.data.msg : response.data);
            }
          })
          .catch(function () {
            axiosThis.cardloader = false;
          });
      } else {
        this.cardloader = false;
        this.$emit("snack", 2, "Only PDF file formats are supported.");
      }
    },
    noFileUPload() {
      this.cardloader = true;
      this.setNoCams();
      let formData = new FormData();
      formData.append("fno", "No");

      var axiosThis = this;
      axios
        .post(api.api_url + `/uploadSignupUserFiles/` + atob(localStorage.getItem("hash")), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.data["msg"] == "All Files Upload sucessfully") {
            axiosThis.toNext();
          } else {
            axiosThis.cardloader = false;
            axiosThis.$emit("snack", 2, response.data.msg ? response.data.msg : response.data);
          }
        })
        .catch(function () {
          axiosThis.cardloader = false;
        });
    },
    bankstatmentRequest() {
      let datao = JSON.stringify({
        seg_cam: true,
        cam_mobile: this.mobile_num,
        cam_rmobile: atob(localStorage.getItem("hash")),
        cam_retry: this.camretry + 1,
      });

      let configo = {
        method: "post",
        url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
        headers: {
          "Content-Type": "application/json",
        },
        data: datao,
      };
      let axiosThis = this;
      axios
        .request(configo)
        .then((response) => {
          if (response.data.data_type.cam_mobile) {
            axiosThis.$v.$touch();
            axiosThis.ash = true;
            axiosThis.segis = 10;
            let json_data = {
              mobile: axiosThis.mobile_num,
            };

            var config = {
              method: "post",
              url: api.cams + "/login/auth",
              headers: {"Content-Type": "application/json"},
              data: JSON.stringify(json_data),
            };
            axios(config)
              .then(function (response) {
                if (response.data) {
                  // window.location.href = response.data["redirectionurl"];
                  axiosThis.iframurl["url"] = response.data["redirectionurl"];
                  axiosThis.iframurl["stat"] = true;
                }
                axiosThis.segis = 0;
              })
              .catch(function (error) {
                console.log(error);
                axiosThis.$emit("snack", 0, error);
                axiosThis.overlay = false;
              });
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    bankstatmentCheckRequest() {
      if (this.srcref) {
        let json_data = {
          mobile: this.mobile_num ? this.mobile_num : atob(localStorage.getItem("hash")),
          srcref: this.srcref,
        };
        var config = {
          method: "post",
          url: api.cams + "/checkStatementStatus",
          headers: {"Content-Type": "application/json"},
          data: JSON.stringify(json_data),
        };
        var axiosThis = this;
        axios(config)
          .then(function (response) {
            if (response.data && response.data.status == "success") {
              localStorage.setItem("camsrcref", response.data.srcref);
              axiosThis.fno = "yes";
              axiosThis.befno = "yes";
              axiosThis.linktype = "cam";
              let ststdata = response.data;
              if (response.data.data == "ACTIVE") {
                let datao = JSON.stringify({
                  srcref: ststdata.srcref,
                  cam_segis: true,
                });
                let configo = {
                  method: "post",
                  url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: datao,
                };
                axios
                  .request(configo)
                  .then((response) => {
                    if (response.data.data_type) {
                      axiosThis.statusValue = ststdata;
                      axiosThis.segis = 2;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    axiosThis.$emit("snack", 0, error);
                  });
                axiosThis.$emit("snack", 1, response.data.status ? `Consent can be submitted Successfully` : response.data);
              } else {
                axiosThis.$emit("snack", 0, response.data.status ? response.data.status : response.data);
              }
            } else {
              axiosThis.$emit("snack", 0, response.data);
            }
            axiosThis.overlay = false;
            axiosThis.dataloader = false;
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.$emit("snack", 0, error);
            axiosThis.overlay = false;
          });
      }
    },
    segment_selection(v) {
      var cupcode = this.successcoupon ? this.couponInput : null;
      var checkStudent = this.heisStudent ? "Student" : "AccountChargesZero";
      let eqt = this.nb_debt && !this.nb_equity && !this.nb_fno && !this.nb_currency && !this.nb_commodity && !this.m_commodity ? this.nb_debt : this.nb_fno && !this.nb_equity ? this.nb_fno : this.nb_equity;
      // let eqt = (this.nb_fno && !this.nb_equity && !this.nb_debt && !this.nb_currency && !this.nb_commodity && !this.m_commodity) ? this.nb_fno : this.nb_equity
      var config = {
        method: "post",
        url:
          api.api_url +
          "/segmentUpdation?mob=" +
          atob(localStorage.getItem("hash")) +
          "&nb_equity=" +
          eqt +
          "&nb_fno=" +
          this.nb_fno +
          "&nb_debt=" +
          this.nb_debt +
          "&nb_currency=" +
          this.nb_currency +
          "&nb_commodity=" +
          this.nb_commodity +
          "&m_commodity=" +
          this.m_commodity +
          "&couponcode=" +
          cupcode +
          "&student=" +
          checkStudent,
        headers: {},
      };
      var axiosThis = this;
      axios(config)
        .then(function (response) {
          if (eqt) {
            axiosThis.setMTFstat(config.url);
          }
          if (response.data && v == "No") {
            axiosThis.noFileUPload();
          }
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
  },
  computed: {
    numberErrors() {
      const errors = [];
      if (this.mobile_num.length != 10 && !this.ash) {
        this.$v.$reset();
      }
      if (!this.$v.mobile_num.$dirty) return errors;
      !this.$v.mobile_num.required && errors.push("Mandatory Field");
      !this.$v.mobile_num.numeric || (!this.$v.mobile_num.minValue && errors.push("Enter a valid number"));
      return errors;
    },
  },
};
</script>
